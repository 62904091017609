<template>
  <Layout class="GameLibrary">
    <div
      v-loading="statisticsLoading"
      class="overview"
    >
      <div class="items">
        <div class="name">
          检索steam游戏数
        </div>
        <div class="num">
          {{ statisticsInfo.staticsTotalNum | numberFilter }}
        </div>
      </div>
      <div class="items">
        <div class="name">
          已入库游戏数
        </div>
        <div class="num">
          {{ statisticsInfo.staticsEditedNum | numberFilter }}
        </div>
      </div>
      <div class="items">
        <div class="name">
          待入库游戏数
        </div>
        <div class="num">
          {{ statisticsInfo.staticsWaitEditNum | numberFilter }}
        </div>
      </div>
    </div>
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item prop="keyWord">
          <el-input
            v-model="formData.keyWord"
            clearable
            placeholder="sourceId / 游戏名称"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item prop="status">
          <el-select
            v-model="formData.editStatus"
            clearable
          >
            <el-option
              v-for="(item, index) in statusList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
      <template #right>
        <el-button
          type="prrmary"
          @click="handleCreateGame"
        >
          新建/爬取游戏
        </el-button>
      </template>
    </BHeader>
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="steam游戏编号"
        prop="sourceId"
        width="120px"
      />
      <el-table-column
        label="游戏名称"
        prop="gameName"
        min-width="150px"
      />
      <el-table-column
        label="创建时间"
        prop="createTime"
      />
      <el-table-column
        label="最后更新时间"
        prop="updateTime"
      />
      <el-table-column
        label="编辑状态"
        prop="editStatus"
        width="100px"
      >
        <template #default="{row}">
          {{ statusMap[row.editStatus] || '/' }}
        </template>
      </el-table-column>
      <el-table-column
        label="图片数量"
        prop="imageNum"
        width="100px"
      />
      <el-table-column
        label="操作"
        width="100px"
      >
        <template #default="{row}">
          <el-button
            type="text"
            @click="goEditGame(row)"
          >
            查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="querySpiderGameList"
    />

    <el-dialog
      title="新建爬取游戏"
      :visible.sync="newGameVisible"
      width="500px"
    >
      <el-form
        ref="newGame"
        :model="newGameForm"
      >
        <el-form-item
          label="输入SteamId:"
          prop="sourceId"
          :rules="[
            { required: true, message: 'sourceId必填'}
          ]"
        >
          <el-input v-model="newGameForm.sourceId" />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="newGameVisible = false">取 消</el-button>
        <el-button
          :loading="newGameLoading"
          type="primary"
          @click="handleConfirmAddTash"
        >确 定</el-button>
      </span>
    </el-dialog>
  </Layout>
</template>

<script>
import {
  getSpiderGameList,
  getSpiderStatics,
  addTask
} from '@/api/gameList.js'
export default {
  name: 'GameLibrary',
  filters: {
    numberFilter (num) {
      if (num === undefined || num === null) {
        return '/'
      }
      return num
    }
  },
  data () {
    return {
      statisticsLoading: false,
      statisticsInfo: {},
      statusList: [
        { name: '未编辑', value: 1 },
        { name: '已编辑', value: 2 }
      ],
      statusMap: {
        1: '未编辑',
        2: '已编辑'
      },
      formData: {
        keyWord: '',
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      newGameVisible: false,
      newGameLoading: false,
      newGameForm: {
        platform: 'steam',
        sourceId: ''
      }
    }
  },
  created () {
    this.handleSearch()
    this.querySpiderStatics()
  },
  methods: {
    handleSearch () {
      this.formData.pageNum = 1
      this.querySpiderGameList()
    },
    querySpiderGameList () {
      this.listInfo.loading = true
      getSpiderGameList(this.formData)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    querySpiderStatics () {
      this.statisticsLoading = true
      getSpiderStatics({})
        .then(res => {
          if (res.code === 200) {
            this.statisticsInfo = res.data || {}
          }
        })
        .finally(() => {
          this.statisticsLoading = false
        })
    },
    handleCreateGame () {
      this.newGameForm.sourceId = ''
      this.newGameVisible = true
    },
    handleConfirmAddTash () {
      this.$refs.newGame.validate(valid => {
        if (valid) {
          this.newGameLoading = true
          addTask(this.newGameForm)
            .then(res => {
              if (res.code === 200) {
                this.newGameVisible = false
                this.$message.success('新建任务成功')
              }
            })
            .finally(() => {
              this.newGameLoading = false
            })
        }
      })
    },
    goEditGame (row) {
      this.$router.push({
        name: 'GameLibraryEdit',
        query: {
          platform: row.platform,
          sourceId: row.sourceId
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
  .GameLibrary {
    .overview {
      display: flex;
      // padding: 0 0 20px 0;
      min-width: 880px;
      margin-bottom: 10px;
      .items {
        width: 210px;
        height: 100px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .num {
          font-size: 26px;
          margin-bottom: 10px;
          .unit {
            font-size: 14px;
          }
        }
        .name {
          font-size: 12px;
        }
      }
    }
  }
</style>
